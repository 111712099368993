export default function About() {
    return (
        <article className="about-us">
            <section className="about">
                <h2>Academic Excellence and Joy</h2>
                <p className="about-subtext">Welcome to the Study for Success Academy, where academic excellence meets positivity and joy! At our tuition centre, we are dedicated to fostering a culture of enthusiasm and happiness, ensuring that every student feels motivated and supported on their educational journey. Our team of experienced, qualified, and DBS-checked educators is committed to providing top-notch tutoring that goes beyond the textbooks, helping students not only understand the material but also instilling a love for learning. We pride ourselves on offering competitive prices without compromising the quality of education. Additionally, we provide the flexibility of both online and face-to-face lessons, catering to the diverse needs of our students. With a proven track record of achieving excellent results, we take pride in being a beacon of success for our students. Join the Study for Success Academy, where we believe that a positive mindset is the key to unlocking academic achievements and creating a bright future.</p>
            </section>

            <section className="double-image">
                <img className="about-1" src={require('../../../assets/students/1.png')} alt="Study for Success Academy students studying"></img>
                {/* <img className="about-2" src={require('../../../assets/students/1.png')} alt="Study for Success Academy classroom"></img> */}
            </section>
        </article>
    );
}
