import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer>
      <menu>
        <li className="img-footer">
          <img
            src={require("/Users/emon/Downloads/Little-Lemon-Project-main/Little-Lemon-Restaurant-master/Little-Lemon-Restaurant-Front End Meta/src/assets/footer-logo-2.png")}
            alt="Little Lemon logo"
          ></img>
        </li>
        <li>
          <h1 className="footer-header">Navigation</h1>
          <ul className="footer-links">
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/our-services">Our Services</Link>
            <Link to="/why">Why Choose Us</Link>
            <Link to="/contact">Contact Us</Link>
            <a
              href={"https://forms.gle/7tMUfLXNd6dUeR379"}
              target="_blank"
              rel="noreferrer"
            >
              Register
            </a>
          </ul>
        </li>
        <li>
          <h1 className="footer-header">Contact</h1>
          <ul className="footer-links">
            <li>The Study for Success Academy</li>
            <li>102 Cranbrook Road</li>
            <li>Wellesley House, Second Floor</li>
            <li>Ilford, IG1 4NH</li>
            <br></br>
            <li>Phone: 02071936597</li>
            <li>Mobile: 07883087170 (Towhid Chowdhury)</li>
            <li>Mobile: 07950640274 (Liza Chowdhury)</li>
            <br></br>
            <a
              href="mailto:info@studyforsuccess.org"
              target="_blank"
              rel="noreferrer"
            >
              info@studyforsuccess.org
            </a>
          </ul>
        </li>
        <li>
          <h1 className="footer-header">Connect</h1>
          <ul className="footer-links">
            {/* Add your social media links here */}
            <a
              href="https://www.facebook.com/studyforsuccess"
              target="_blank"
              rel="noreferrer"
            >
              Facebook
            </a>
            <a
              href="https://www.instagram.com/studyforsuccess/"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
            <a
              href="https://www.youtube.com/studyforsuccess"
              target="_blank"
              rel="noreferrer"
            >
              YouTube
            </a>
          </ul>
        </li>
      </menu>
    </footer>
  );
}
