// Assume this is the structure of your WhyChooseUs component

export default function Order() {
  return (
    <div className="why-choose-us-container">
      <h1>Why Choose Us</h1>
      <div className="choose-us-point">
        <h2>Explore the Study for Success Academy</h2>
        <p>Where our dedication to your academic journey transcends conventional tutoring.</p>
      </div>
      
      <div className="choose-us-point">
        <h2>Choose us for an unparalleled educational experience</h2>
        <p>That seamlessly blends enthusiasm, support, and excellence.</p>
      </div>
      
      <div className="choose-us-point">
        <h2>Our team comprises seasoned educators</h2>
        <p>Each meticulously chosen for their experience, qualifications, and DBS clearance, ensuring a safe and enriching learning environment.</p>
      </div>

      {/* Add similar sections for prioritized values and benefits */}
      
      <div className="choose-us-point">
        <h2>To further enhance your learning experience, we offer:</h2>
        <ul>
          <li>Competitive pricing without compromising on the quality of education.</li>
          <li>Flexibility, whether you opt for online learning or the personalized touch of face-to-face sessions.</li>
        </ul>
      </div>

      <div className="choose-us-summary">
        <p>Our commitment to your success remains unwavering, with a track record that speaks volumes about the effectiveness of our approach.</p>
        <p>So, why choose the Study for Success Academy? Choose us for an educational journey that transcends the ordinary, where passion meets success, and where every student is guided towards a future of academic achievement and personal fulfillment.</p>
      </div>
    </div>
  );
}
