import React from 'react';
import '../../App.css';


export default function ServicesPage() {
  return (
    <div className="services-container">
      <section className="our-services">
        <h1>Our Services</h1>
        <p>
          For every new student at the Study for Success Academy, we provide a baseline
          assessment. This informs our teachers as to how much your child is capable of
          doing, and helps us prepare suitable and purposeful lessons, focusing on
          certain different things for each individual.
        </p>
        <p>
          Students will also be given homework every lesson, which they will be expected
          to complete before the next session. Termly reports will be given to parents,
          so that you are aware of your child’s progress in their learning.
        </p>
      </section>

      <section className="eleven-plus">
        <h2>11 Plus</h2>
        <p>
          We provide sessions for Years 4 and 5, in preparation for the 11 plus exams for
          grammar and independent schools, offering tuition for Maths and English,
          alongside Verbal and Non-Verbal Reasoning, for all schools.
        </p>
        <p>
          This includes excellent support in Creative Writing for the examinations. We
          teach students in small groups of up to 6, whilst still giving enough attention
          and support to each child, and we also hold one-to-one lessons.
        </p>
      </section>

      <section className="sats">
        <h2>SATs</h2>
        <p>
          We support Year 6 students in preparation for the SATs. This is provided in
          groups of no more than 6, or one-to-one lessons, with sessions containing
          tutoring in both Maths and English, whilst still providing sufficient support
          for each individual.
        </p>
      </section>

      <section className="maths">
        <h2>Maths (Year 7 to GCSE)</h2>
        <p>
          Our courses include one-to-one group sessions for Maths, ranging from Years 7
          to 11, supporting normal lessons, as well as preparation for the GCSEs. These
          groups are likely to contain up to 6 students of the same year group, so that
          each child gets the correct level of learning needed for them.
        </p>
      </section>

      <section className="english">
        <h2>English (Year 7 to GCSE)</h2>
        <p>
          We provide sessions in groups of 6, or one-to-one, for English Literature and
          Language, from Year 7 to Year 11. With the knowledge of the national
          curriculum, we intend to assist students with classwork, and to also bring them
          up to GCSE level.
        </p>
      </section>

      <section className="coding">
        <h2>Coding (Year 7 to GCSE)</h2>
        <p>
          We offer group and one-to-one lessons for coding, which is available to all
          secondary school children, accompanying their normal lessons, alongside aiding
          them with their Computer Science GCSEs. These sessions are in small groups of
          no more than 6, ensuring that individuals are benefiting from the lessons.
        </p>
      </section>
    </div>
  );
}
