// Assume this is the structure of your ContactUs component
import React from 'react';
import '../../App.css';
import MessageSending from '../Messagesending';


export default function ContactUs() {
    return (
      <div className="contact-us-container">
        <div className="contact-info">
          <h1>Contact Us</h1>
          <p>Phone number: 02071936597</p>
          <p>Mobile: 07883087170 – Towhid Chowdhury (Text only, we will call back)</p>
          <p>07950640274 – Liza Chowdhury (Text only, we will call back)</p>
          <p>Email address: info@studyforsuccess.org</p>
          <p>Website: www.studyforsuccess.org</p>
  
          <h2>Address:</h2>
          <p>102 Cranbrook Road</p>
          <p>Wellesley House</p>
          <p>Second Floor</p>
          <p>Ilford</p>
          <p>IG1 4NH</p>
        </div>
  
        <div className="map-container">
          <img
          src={require("../../assets/map.png")}
          alt="Google Maps Screenshot"
          style={{ width: "100%", borderRadius: "8px" }}
        />
          </div>
  
        <div className="contact-form">
        
        </div>
      </div>
    );
  }
  